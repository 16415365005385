@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');

*, html {
  box-sizing: border-box;
  font-family: "Play", "Palatino", sans-serif;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.skip-link {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

.p-left {
  padding-left: 16px;
}

.p-left-2 {
  padding-left: 32px;
}

